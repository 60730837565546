.input-container-search {
  display: flex;

  border-radius: 2px;
  margin-right: 10px;
  margin-bottom: 16px;
  width: 100%;

  border: 1px solid #b8b8b8;
  border-radius: 8px;
  background-color: #f8f8f8;
}

.input-container-search input {
  width: 100%;
  height: 100%;
  padding: 8px 20px;
  outline: none;
  border: none;
  font-size: 18px;
  font-family: "Montserrat";

  background-color: transparent;
}


.input-container-search .icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  color: #5a5a5a;
}

.input-container-search input::placeholder {
  color: #b1b1b1;
}
