.module-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  border-radius: 2rem;
  height: 3.2rem;
  width: 3.2rem;
  color: white;
  margin-right: 1rem;
}

.module-title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: "Montserrat";
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.module-description-container {
  color: rgb(68, 68, 68);
  margin-bottom: 2rem;
}

.module-container {
  /* display: flex;
  flex-direction: column;
  justify-content: space-between; */
  padding: 1rem;
  box-shadow: 0px 0px 10px rgb(69, 69, 69);
  /* overflow: hidden; */
}

.module-button-container {
  font-weight: 600;
  padding: 0.6rem;
  border-radius: 0.5rem;
  margin-right: 1rem;
  cursor: pointer;
  transition: 200ms;
}

.module-button-activate-container {
  color: white;
  background-color: #1967d2;
}

.module-button-activate-container:hover {
  background-color: #3674ca;
}

.module-button-moreinfo-container {
  color: #979797;
  background-color: white;
}

.module-button-moreinfo-container:hover {
  color: #329adf;
  background-color: #e1f3fc;
}

.module-button-main-container {
  display: flex;
}

.module-title-info {
  padding: 0.2rem;
  margin-left: 0.4rem;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  border-radius: 1rem;
  font-size: 1rem;
}

.module-title-beta {
  background-color: mediumslateblue;
  color: white;
}

.module-title-planned {
  background-color: rgb(221, 131, 80);
  color: white;
}

.module-button-container-disabled {
  cursor: default;
  background-color: #979797;
}

.module-button-container-disabled:hover {
  background-color: #979797;
}

@media screen and (max-width: 840px) {
  .module-title-container {
    font-size: 1.2rem;
  }
}
