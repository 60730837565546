.color-input {
  margin-bottom: 1rem;
  height: 64px;
  width: 64px;
}

.color-input > input {
  cursor: pointer;
  opacity: 0;
  display: block;
  height: 100%;
  width: 100%;
}
