.form-elem-container {
  padding: 1rem;
  margin-bottom: 0.5rem;
  border: 1px dashed gray;
  border-radius: 7px;
  cursor: move;
}

.add-form-btn {
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  background-color: #e8ebff;
  display: flex;
  justify-content: center;
  align-items: center;

  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.add-form-btn-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}
