.qrCode-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.confirmation-container-status {
  flex: 1;
}

.main-confirmation-container {
  margin-top: 2rem;
  display: flex;
}

.confirmation-title {
  font-weight: bold;
  padding-right: 1rem;
}

.bookingData-loading-container {
  display: flex;
  flex-direction: column;
}

.bookingData-loading-spinner-container {
  display: flex;
  justify-content: center;
  margin-top: 5rem;
  margin-bottom: 5rem;
}
