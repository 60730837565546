.payment-method-img {
  height: 18px;
  width: 18px;
}

.payment-method-wrapper {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.edit-payment-method-icon {
    color: gray;
    cursor: pointer;
}