.book-btn {
  font-size: 2rem;
  font-weight: 100;
  flex-direction: row;
  width: 100%;
  padding: 10px;
  border: none;
  outline: none;
  background-color: rgb(0, 165, 0);
  margin: 0;
  margin-bottom: 20px;
  cursor: pointer;
  font-family: "Montserrat";
  transition: background-color 500ms;
}

.book-btn-red {
  background-color: red;
}

.book-btn-loading {

}

.book-btn-disabled {
  cursor: default;
  background-color: rgb(139, 139, 139);
}

.book-btn-inner-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.book-btn-loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
