.main-info-screen h1 {
  margin-bottom: 1rem;
}

.main-info-screen h2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.pre-line {
  white-space: pre-line;
}
