.menubar-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0 0 5px 5px rgb(0 0 0 / 25%);
  padding-left: 1rem;
  padding-right: 1rem;
  height: var(--header-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
}

.menubar-path-main-container {
  display: flex;
  align-items: center;
}

.menubar-path-container {
  font-size: 0.85rem;
  font-weight: bold;
  color: rgb(78, 78, 78);
  /* display: none; */
  margin-left: var(--navbar-width);
  font-family: "Montserrat";
}

@media (max-width: 1200px) {
  .menubar-path-container {
    margin-left: 1.5rem;
  }
}

.menubar-container-icon {
  display: block;
}

.menubar-path-text {
  text-decoration: none;
  color: rgb(78, 78, 78);
}

@media (max-width: 768px) {
  .menubar-path-container {
    font-size: 0.75rem;
  }
}

.menu-bell-icon {
  cursor: pointer;
}

@keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(-10deg);
  }
}

.menu-bell-icon:hover {
  animation: wiggle 0.5s 1;
}

.menubar-bell-icon {
  position: relative;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 102, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 6px rgba(255, 102, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 102, 0, 0);
  }
}

.menubar-bell-new-notification {
  position: absolute;
  background-color: rgb(255, 102, 0);
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 50%;
  right: 0;
  z-index: 1;

  box-shadow: 0 0 0 0 rgba(255, 102, 1);
  transform: scale(1);
  animation: pulse 2s 10;
}

.notifications-container {
  position: absolute;
  height: 0;
  width: 20rem;
  max-width: 70vw;
  right: 0;
  transform: translate(0, 20px);
  overflow: auto;
  transition: height 0.3s ease;
  overflow: hidden;
}

.notifications-inner-container {
  background-color: white;
  margin: 0 0px 20px 20px;
  /* box-shadow: none; */
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  height: 90%;
  box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.2);
}

.notifications-inner-container::-webkit-scrollbar {
  width: 0.25rem;
}

.notifications-inner-container::-webkit-scrollbar-track {
  background: #e0e0e0;
}

.notifications-inner-container::-webkit-scrollbar-thumb {
  background: #bebebe;
}

.show-notification {
  display: block;
  height: 15rem;
  max-height: 70vh;
}

.notification-inner-container {
  width: 100%;
  border-bottom: 1px solid lightgray;
  padding: 0.5rem;
  padding-left: 1rem;
  cursor: pointer;
}

.notification-inner-container:hover {
  background-color: rgb(240, 240, 240);
}

.notification-title-container {
  font-weight: bold;
  margin-bottom: 0.2rem;
}

.notification-markallasread {
  display: flex;
  justify-content: flex-end;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 0.25rem;
  border-bottom: 1px solid lightgray;
  color: rgb(95, 95, 95);
  cursor: pointer;
  font-size: 0.85rem;
  font-weight: bold;
}

.notification-markallasread:hover {
  background-color: rgb(248, 248, 248);
}

.notification-loading-container {
  display: flex;
  padding-top: 5rem;
  padding-bottom: 5rem;
  justify-content: center;
  align-items: center;
}

.notification-uptodate {
  display: flex;
  padding-top: 5rem;
  padding-bottom: 5rem;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media screen and (max-width: 480px) {
  .notification-uptodate {
    font-size: 0.9rem;
  }
}
