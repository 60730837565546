.accept-container {
  display: flex;
  margin-bottom: 1rem;
  padding: 2px;
}

.accept-container-glowing {
  /* box-shadow: 0px 0px 40px 10px rgb(211, 211, 211); */
  background-color: rgb(255, 211, 211);
}

.accept-container-checkbox {
  margin-right: 0.5rem;
  margin-top: 3px;
}
