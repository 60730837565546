.t-skeleton {
  opacity: 0.7;
  animation: t-skeleton-loading 1s linear infinite alternate;
}

.t-skeleton-text {
  width: 100%;
  height: 0.5rem;
  margin-bottom: 0.25rem;
  border-radius: 0.125rem;
}

.t-skeleton-text-35 {
  width: 35%;
}

.t-skeleton-text-37 {
  width: 35%;
}

.t-skeleton-text-40 {
  width: 40%;
}

.t-skeleton-text-60 {
  width: 60%;
}

.t-skeleton-text-50 {
  width: 50%;
}

@keyframes t-skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
