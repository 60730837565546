.manates-wrapper {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  margin-bottom: 1rem;
  max-height: 18rem;
  min-height: 10rem;
  overflow-y: auto;
  padding-right: 1rem;
}

.modal-subtitle {
  font-size: 1.5rem;
}

.add-other-payment-method-disabled {
  background-color: grey !important;
}

.manates-wrapper::-webkit-scrollbar {
  width: 0.3rem;
}

.manates-wrapper::-webkit-scrollbar-track {
  background: #d8d8d8;
}

.manates-wrapper::-webkit-scrollbar-thumb {
  background: #acacac;
  border-radius: 10px;
}

.add-other-payment-method {
  color: rgb(59, 59, 226);
  padding-top: 1rem;
  padding-bottom: 1rem;
  cursor: pointer;
}

.add-other-payment-wrapper {
  display: flex;
  justify-content: center;
}

.add-other-payment-method-confirm {
  display: inline-block;
  color: rgb(59, 59, 226);
  border-radius: 6px;
  border: 1px solid rgb(86, 86, 227);
  padding: 1rem;
  cursor: pointer;
  margin-bottom: 2rem;
  transition: all 0.2s ease-in-out;
}

.add-other-payment-method-confirm:hover {
  background-color: rgb(86, 86, 227);
  color: white;
}
