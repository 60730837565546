.multistringselector-container {
  display: flex;
  z-index: 0;
}

.multistringselector-container input {
  flex: 1;
  border: 1px solid #ccc;
  border-radius: 30px;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  color: #000;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  outline: none;
  margin-bottom: 1rem;
}
