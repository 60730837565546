.branch-modal-inner {
  z-index: 1001;
  min-width: 50%;
  max-width: 90vmin;
  padding: 50px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: white;
  color: black;
  border-radius: 25px;
  position: relative;
}

@media screen and (max-width: 500px) {
  .branch-modal-inner {
    max-width: 95vmin;
  }
}

.branch-title {
  font-weight: 100;
  font-family: "Montserrat";
}

.branch-text {
  font-weight: 100;
  font-family: "Montserrat";
  font-size: 1rem;
}
