.table {
  width: 100%;
  margin-bottom: 1rem;
  color: rgb(88, 88, 88);
  border-spacing: 0px;
  border: 1px solid var(--border-color);
  border-radius: 6px;
}

.table td {
  border-top: 1px solid var(--border-color);
  height: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.table th {
  text-align: start;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #fbfbfb;
  padding-right: 0.5rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (max-width: 450px) {
  .max-width-450 {
    display: none;
  }
}

@media (max-width: 600px) {
  .max-width-600 {
    display: none;
  }
}

@media (max-width: 700px) {
  .max-width-700 {
    display: none;
  }
}

@media (max-width: 800px) {
  .max-width-800 {
    display: none;
  }
}

@media (max-width: 950px) {
  .max-width-950 {
    display: none;
  }
}

@media (max-width: 1000px) {
  .max-width-1000 {
    display: none;
  }
}

@media (max-width: 1200px) {
  .max-width-1200 {
    display: none;
  }
}

@media (max-width: 1400px) {
  .max-width-1400 {
    display: none;
  }
}

.display-none {
  display: none;
}
