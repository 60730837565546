.navbar-element {
  display: flex;
  flex-direction: row;
  color: var(--primary-text-color);
  font-size: 1.2rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  padding-left: 1rem;
  /* border-bottom: 1px solid rgb(26, 25, 29); */
  cursor: pointer;
  text-decoration: none;
}

.navbar-element-selected {
  color: white;
  background-color: var(--secondary-color);
}

.navbar-element:last-child {
  border-bottom: none;
}

.navbar-element > .navbar-icon-container {
  margin-right: 1rem;
  min-width: 2rem;
  position: relative;
}

.navbar-element-logo {
  font-weight: bold;
  font-family: "Montserrat";
}

.navbar-element > .navbar-icon-logo-container {
  margin-right: 0.75rem;
}

.navbar-create-element {
  justify-content: center;
  padding-right: 1rem;
}

.navbar-back-element {
  background-color: rgb(34, 34, 34);
  border-top: 1px solid rgb(50, 50, 50);
  justify-content: center;
  padding-right: 1rem;
  height: none;
}

.navbar-element-logo {
  flex: 0 0 auto;
  background-color: var(--tertiary-color);
  color: var(--tertiary-text-color);
  height: var(--header-height);
  /* border-bottom: 1px solid rgb(50, 50, 50); */
  justify-content: center;
  align-items: center;
  padding: 0px;
}

.navbar-element-user {
  flex-direction: column;
  gap: 1rem;
  background-color: var(--user-element-color);
  margin-bottom: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 0;
}

.navbar-element-user-link {
  padding-left: 1rem;
}

.navbar-profile-pic {
  width: 4rem;
  height: 64px;
  width: 64px;
}

.navbar-element-user-textcontainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 1.5rem;
}

.navbar-element-user-logout {
  border: 1px solid white;
  color: white;
  text-decoration: none;
  border-radius: 10px;
  padding: 0.5rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  text-align: center;
  background-color: transparent;
  transition: all 300ms ease;
}

.navbar-element-user-logout:hover {
  color: black;
  background-color: white;
}

.navbar-element-indented {
  padding-left: 4rem;
}

.name-spaceholder-container {
  display: flex;
}

.name-spaceholder {
  height: 1em;
  width: 4rem;
  background-color: var(--secondary-color);
  border-radius: 20px;
  margin-right: 0.5rem;
  animation: skeleton-loading 1s linear infinite alternate;
}

.name-spaceholder-fName {
  width: 4rem;
}

.name-spaceholder-lName {
  width: 5rem;
}

@keyframes skeleton-loading {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.7;
  }
}

.navbar-element-user-namecontainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.navbar-user-element-usersettings-icon {
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-bell-new-notification {
  position: absolute;
  background-color: rgb(255, 102, 0);
  height: 1rem;
  min-width: 1rem;
  border-radius: 50%;
  right: 0;
  z-index: 1;
  top: 0;
  right: 0;
  box-shadow: 0 0 0 0 rgba(255, 102, 1);
  transform: scale(1) translate(0.25rem, -0.25rem);
  animation: pulseNavbar 2s 10;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  font-weight: bold;
  padding: 4px;
}

@keyframes pulseNavbar {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 102, 0, 0.7);
  }

  70% {
    box-shadow: 0 0 0 4px rgba(255, 102, 0, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 102, 0, 0);
  }
}

.select-family-member {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.1rem;
  padding-left: 1rem;
}

.family-member-wrapper {
  display: flex;
  flex-direction: column;
}

.family-member {
  padding: 0.5rem 0rem;
  transition: all 0.1s ease-in-out;
  padding-left: 1rem;
  font-size: 1rem;
}

.family-member:hover {
  box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.1);
}

.family-member-selected::after {
  content: " (ausgewählt)";
}
