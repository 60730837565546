.main-btn {
  width: max-content;
  padding: 0.75rem;
  background-color: var(--primary-color);
  color: var(--primary-text-color);
  border-radius: 30px;
  cursor: pointer;
  outline: none;
  border: none;
  transition: all 0.1s ease-in-out;
}

.main-btn:hover {
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
}
