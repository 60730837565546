.multi-select-container {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
}

.multi-select-inner-container {
  display: flex;
  gap: 0.5rem;
}

.edit-element-container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.element-container-edit-icon-container {
  display: flex;
  gap: 0.75rem;
  position: absolute;
  right: 0;
}

.edit-element-container-inner {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.switch-textbox-wrapper {
  display: flex;
  gap: 1rem;
  min-height: 2.5rem;
}

.edit-element-edit-container {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}
