.singleselector-container {
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
  z-index: 1;
  font-family: "Montserrat";
  font-weight: normal;
  overflow: visible;
}

.singleselector-element-container {
  display: inline-block;
  border-radius: 1rem;
  padding: 4px;
  padding-left: 7px;
  padding-right: 7px;
  margin-right: 4px;
  border: 2px solid rgb(238, 92, 92);
  flex-direction: row;
  margin-top: 1px;
  margin-bottom: 1px;
}

.singleselector-add-element-container {
  border: 1px solid rgb(133, 133, 133);
  cursor: pointer;
  padding: .5rem 1rem;
  margin-right: 0px;
  border-radius: 4px;
  position: relative;
}

.singleselector-element-container > .point {
  float: left;
  width: 1rem;
  height: 1rem;
  background-color: rgb(238, 92, 92);
  border-radius: 50%;
  margin-right: 4px;
}

.point > .cross-container {
  display: flex;
  flex: 1;
  height: 100%;
  color: white;
  font-size: 10px;
  align-items: center;
  justify-content: center;
  opacity: 0%;
}

.point:hover > .cross-container {
  opacity: 100%;
}

.singleselector-add-container {
  display: none;
  position: absolute;
  background-color: #313131;
  border-radius: 0.5rem;
  padding: 1rem;
  min-width: 16rem;
  transform: translate(calc(-50% + 120px), 30px);
  max-height: 200px;
  z-index: 2;
  font-family: "Montserrat";
  font-weight: normal;
}

.singleselector-show-add-container {
  display: flex;
  flex-direction: column;
}

.singleselector-add-text-container {
  background-color: #242424;
  border-radius: 6px;
  flex-direction: row;
  display: flex;
  margin-bottom: 6px;
}

.singleselector-add-text-container .icon-container {
  display: flex;
  /* flex: 1; */
  padding-right: 6px;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  float: right;
  cursor: default;
  font-size: 1.2rem;
  color: rgb(88, 88, 88);
}

.singleselector-add-text-container > input {
  background-color: transparent;
  border: none;
  outline: none;
  color: white;
  padding: 0.5rem;
  font-size: 1.01rem;
  width: 100%;
}

.singleselector-select-element-container {
  display: flex;
  justify-content: flex-start;
  color: rgb(231, 231, 231);
  padding: 0.75rem;
  border-radius: 6px;
}

.singleselector-select-element-container:hover {
  background-color: #3d3d3d;
}

.singleselector-select-element-container .point {
  float: left;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  background-color: rgb(238, 92, 92);
  margin-right: 0.5rem;
  flex-shrink: 0;
}

.singleselector-scroll-container {
  overflow-y: auto;
}

.singleselector-scroll-container::-webkit-scrollbar {
  width: 0.25rem;
}

.singleselector-scroll-container::-webkit-scrollbar-track {
  background: #1e1e24;
}

.singleselector-scroll-container::-webkit-scrollbar-thumb {
  background: #686868;
}
