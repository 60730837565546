.tag-container {
  display: inline-block;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 14px;
  padding-left: 14px;
  background-color: #335ec8;
  border-radius: 10px;
  color: white;
  margin-bottom: 10px;
  margin-right: 10px;
}

.tag-container-unselected {
  background-color: #5d5d5d;
}
