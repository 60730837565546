.modal-user-table {
  border-spacing: 1rem;
  border-collapse: collapse;
  margin-bottom: 2rem;
}

.modal-user-table thead tr th {
  padding-bottom: 1rem;
  font-size: 1.4rem;
}

.modal-user-table td {
  padding-right: 1vmin;
}

.modal-user-table td:last-child {
  padding-right: 0px;
}

.user-modal-input {
  height: 2.4rem;
  border: 1px solid black;
  outline: none;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1.2rem;
}