.back-container {
    color: rgb(0, 0, 46);
    text-decoration: none;
}

.back-container div {
    margin-bottom: 1rem;
}

.back-element-icon {
    margin-right: 0.5rem;
}