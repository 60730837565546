.mark-as-resolved-btn {
  background-color: #e79838;
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  transition: 100ms;
}

.mark-as-resolved-btn:hover {
  box-shadow: 0px 0px 4px 2px rgb(0 0 0 / 10%);
}
