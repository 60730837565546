.payment-status {
  display: inline-block;
  text-transform: uppercase;
  background-color: aquamarine;
  padding: 2px;
  padding-left: 4px;
  padding-right: 4px;
  color: white;
  font-size: 0.71rem;
  border-radius: 4px;
  transition: 0.2s;
}
