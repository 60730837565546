:root {
  --pricing-table-height: 2.2rem;
  --pricing-table-verticall-spacing: 0.5rem;
  --pricing-table-font-size: 1rem;
  --pricing-table-padding-left: 1rem;
  --pricing-table-font-family: "Montserrat";
}

.pricing-table {
  width: 100%;
  /* table-layout: fixed; */
}

.pricing-time-input {
  font-family: "Montserrat";
  margin: 0;
  width: 100%;
  height: var(--pricing-table-height);
  border: 1px solid darkgray;
  margin-bottom: var(--pricing-table-verticall-spacing);
  font-size: var(--pricing-table-font-size);
  padding-left: var(--pricing-table-padding-left);
  font-family: var(--pricing-table-font-family);
}

.pricing-price-input {
  margin: 0;
  width: 100%;
  height: var(--pricing-table-height);
  border: 1px solid darkgray;
  margin-bottom: var(--pricing-table-verticall-spacing);
  font-size: var(--pricing-table-font-size);
  padding-left: var(--pricing-table-padding-left);
  font-family: var(--pricing-table-font-family);
}

.pricing-icon-container {
  display: flex;
  height: var(--pricing-table-height);
  align-items: center;
  justify-content: center;
}

.pricing-icon-container .pricing-icon {
  cursor: pointer;
}

#price-table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#price-table td,
#price-table th {
  border: 1px solid #ddd;
  padding: 8px;
}

#price-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

#price-table tr:hover {
  background-color: #ddd;
}

#price-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #282828;
  color: white;
}
