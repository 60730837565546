.f-skeleton {
  opacity: 0.7;
  animation: f-skeleton-loading 1s linear infinite alternate;
}

.f-skeleton-text {
  width: 100%;
  height: 0.5rem;
  margin-bottom: 0.25rem;
  border-radius: 0.125rem;
}

.f-skeleton-heading {
  width: 100%;
  height: 1rem;
  margin-bottom: 0.75rem;
  border-radius: 0.125rem;
}

.f-skeleton-btn {
  width: 30%;
  height: 2rem;
  border-radius: 0.125rem;
}

.show-mobile {
  display: none;
}

@media screen and (max-width: 600px) {
  .f-skeleton-heading {
    width: 90%;
  }

  .f-skeleton-btn {
    width: 100%;
  }

  .f-skeleton-img {
    width: 100%;
    height: 10rem;
    border-radius: 0.125rem;
  }

  .show-mobile {
    display: block;
  }
}

@keyframes f-skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
