.small-space {
  margin-top: 8px;
  margin-bottom: 8px;
}

.small-Text-Red {
  color: #d63232;
  font-size: 16px;
  text-align: center;
}
