.br-main-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
}

.menu-container {
  padding: 1rem;
  background-color: white;
  box-shadow: 0 0 10px 10px rgb(0 0 0 / 10%);
  border-radius: 2rem;
  position: fixed;
  top: 0.75rem;
  left: 0.75rem;
  cursor: pointer;
  /* z-index: 1; */
}

.menu-icon {
  cursor: pointer;
  display: none;
}

@media screen and (max-width: 1200px) {
  .menu-icon {
    display: block;
  }
}
