.qrCode-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.confirmation-container-status {
  flex: 1;
}

.main-confirmation-container {
  margin-top: 2rem;
  display: flex;
}

.confirmation-title {
  font-weight: bold;
  padding-right: 1rem;
}

.courseBooking-course-info-container {
  display: flex;
  margin-bottom: 1rem;
  font-family: "Montserrat";
}

.courseBooking-course-info-container h2 {
  margin-bottom: 0.4rem;
}

@media screen and (max-width: 710px) {
  .courseBooking-course-info-container {
    flex-direction: column;
  }

  .courseBooking-course-info-container h2 {
    margin-bottom: 0.5rem;
    margin-top: 0rem;
  }
}

.cancellationContainer {
  margin-top: 2rem;
}

.course-booking-information-table {
  width: 100%;
  margin-bottom: 1rem;
  color: rgb(88, 88, 88);
  border-spacing: 0px;
  border: 1px solid var(--border-color);
  border-radius: 6px;
}

.course-booking-information-btn{
  text-align: right;
  padding-right: 1rem;
}

.course-booking-information-table td:first-child {
  padding-right: 1rem;
  padding-left: 1rem;
}

.course-booking-information td:first-child {
  font-weight: bold;
}

.no-border td {
  border: none !important;
}

.course-booking-information-table td {
  border-top: 1px solid var(--border-color);
  height: 3rem;
}

.course-booking-information-table th {
  text-align: start;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #fbfbfb;
  padding-right: 0.5rem;
}

.course-booking-information-table th:first-child {
  padding-left: 1rem;
  padding-right: 1rem;
}
