.f-skeleton {
  opacity: 0.7;
  animation: f-skeleton-loading 1s linear infinite alternate;
}

.f-skeleton-text {
  width: 100%;
  height: 0.5rem;
  margin-bottom: 0.25rem;
  border-radius: 0.125rem;
}

.f-skeleton-text-35 {
  width: 35%;
}

.f-skeleton-text-37 {
  width: 35%;
}

.f-skeleton-text-40 {
  width: 40%;
}

.f-skeleton-text-60 {
  width: 60%;
}

.f-skeleton-text-50 {
  width: 50%;
}

@keyframes f-skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.feed-thead tr th {
  position: sticky;
  top: 0;
}

.infinity-scroll-feed {
  position: relative;
}

.infinity-scroll-feed::-webkit-scrollbar {
  width: 0.5rem;
}

.infinity-scroll-feed::-webkit-scrollbar-track {
  background: #d8d8d8;
}

.infinity-scroll-feed::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}

.search-bar-wrapper {
  display: flex;
  gap: 1rem;
  margin-bottom: 0.2rem;
}

@media screen and (max-width: 768px) {
  .search-bar-wrapper {
    flex-direction: column;
    gap: 0rem;
  }
}

.feed-color {
  color: #585858;
}

.h-full-center {
  height: 100%;
  display: flex;
  align-items: center;
}
