.radio-btn-container input,
.radio-btn-container label {
  margin-right: 10px;
}

.radio-btn-container {
  margin-bottom: 1rem;
}

.checkbox-news-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
}

.checkbox-news-container input {
  margin-right: 10px;
  margin-left: 10px;
}

.membership-pricing-table {
  margin-bottom: 0.5rem;
  border-spacing: 0;
}

.membership-pricing-table th {
  text-align: left;
  padding-bottom: 0.5rem;
}

.membership-pricing-table td {
  padding-right: 3rem;
}

.membership-pricing-table tbody tr:nth-child(odd) {
  background-color: rgb(227, 227, 227);
}

.bold-text-spacing {
  font-weight: bold;
  margin: 0.75rem 0 0.75rem;
}

.mem-app-no-account-container {
  margin-top: 1rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
}

.mem-app-no-account-container p {
  margin-bottom: 1.5rem;
}

.mem-app-no-account-container a {
  padding-right: 5rem;
  padding-left: 5rem;
  width: fit-content;
  text-decoration: none;
}

@media screen and (max-width: 700px) {
  .mem-app-no-account-container a {
    padding-right: 5rem;
    padding-left: 5rem;
    width: 100%;
  }
}

.department-container {
  column-count: 3;
  column-gap: 1rem;
}

.department-inner-container {
  /* break-inside: avoid; */
  box-sizing: border-box;
}

@media (max-width: 900px) {
  .department-container {
    column-count: 2;
  }
}

@media (max-width: 600px) {
  .department-container {
    column-count: 1;
  }
}
