.to-text-container {
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;
  align-items: center;
  justify-content: center;
}

.multiple-dates-edit-btn {
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;
  justify-content: center;
  align-items: center;
  background-color: rgb(89, 141, 238);
  color: white;
  margin-left: 0.5rem;
  cursor: pointer;
}

.course-remove-btn {
  background-color: rgb(238, 94, 94);
}

.sector-select-main-container {
  display: flex;
  /* flex-direction: column; */
}

.input-text-container {
  display: flex;
  flex: 1;
}

.sector-select-input-container {
  display: flex;
  flex: 1;
}

.select-sector-btn {
  display: flex;
}

@media screen and (max-width: 1400px) {
  .sector-select-main-container {
    flex-direction: column;
  }

  .to-text-container {
    justify-content: left;
    min-width: 5rem;
  }

  .sector-select-input-container {
    flex-direction: column;
    gap: 0.25rem;
  }

  .select-sector-btn {
    display: flex;
    margin-top: 1rem;
    flex: 1;
    justify-content: space-between;
  }

  .multiple-dates-edit-btn {
    flex: 1;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
  }
}
