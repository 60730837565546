.subtitle-container {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.subtitle-container h3 {
  margin-right: 0.2rem;
}

.info-icon-container {
  font-size: 0.9rem;
  cursor: pointer;
  color: rgb(56, 56, 56);
  position: relative;
  z-index: 0;
}

.info-icon-container:hover {
  z-index: 10;
}

.info-text-container {
  position: absolute;
  display: none;
  align-items: center;
  transform: translate(16px, calc(-50% - 0.45rem));
  max-width: 50vw;
  min-width: 40vw;
}

.info-text-inner-container {
  background-color: var(--primary-color);
  color: var(--primary-text-color);
  padding: 1.25rem;
  border-radius: 10px;
  box-shadow: 0 0 5px 5px rgb(0 0 0 / 25%);
}

.info-text-triangle {
  width: 0px;
  height: 0px;

  border-top: 10px solid transparent;
  border-left: 0px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid var(--primary-color);
  z-index: 1;
}

.info-icon-container:hover .info-text-container {
  display: flex;
}
