.action-btn-container {
  position: relative;
  padding: 0px;
  /* padding-top: 4px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-btn {
  outline: none;
  border: 1px solid white;
  background-color: transparent;
  color: white;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 20px;
  cursor: pointer;
}

.loading-table-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.invite-user-row-selected td {
  background-color: rgb(241, 241, 241);
}

.invite-user-row-lower td {
  border-top: 0 !important;
  background-color: rgb(241, 241, 241);
}

.trash-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  border-radius: 4px;
  width: 36px;
  background-color: rgb(224, 224, 224);
  font-size: 20px;
  margin-left: 0.5rem;
  transition: all 200ms;
}

.trash-icon-container:hover {
  background-color: rgb(216, 216, 216);
}

.status-container {
  display: flex;
  flex: 1;
  height: 36px;
  line-height: 36px;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-right: 14px;
  padding-left: 14px;
  border-radius: 4px;
  color: white;
  background-color: #38b0de;
  margin-bottom: 10px;
}

.saved-container {
  color: white;
  background-color: #7bc333;
}

.edit-container {
  color: white;
  background-color: #38b0de;
}

.edit-container:hover {
  background-color: #7bc333;
}

.edit-container::after {
  content: "wird bearbeitet...";
}

.edit-container:hover::after {
  content: "speichern";
  background-color: #7bc333;
}

.table-input-margin-top {
  margin-top: 6px;
  margin-bottom: 6px;
}

.branch-table-fieldset {
  border: 1px solid rgb(172, 172, 172);
  border-radius: 6px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  overflow: visible;
}

.branch-table-fieldset legend {
  margin-left: 1rem;
  padding-right: 6px;
  padding-left: 6px;
}

.inner-branch-table {
  width: 100%;
  border-spacing: 0px;
}

.inner-branch-table th {
  background-color: transparent !important;
}

.table-main-content {
  width: 100%;
}

.table-onboarding-selector {
  width: 25%;
}

.user-table-invite-status {
  padding: 4px;
  padding-right: 8px;
  padding-left: 8px;
  background-color: cornflowerblue;
  color: white;
  display: inline-block;
  border-radius: 6px;
  font-weight: 0.8rem;
}

.user-table-invite-status-created {
  background-color: #ff5c5c;
}

.user-table-invite-status-created::after {
  content: "erstellt";
}

.user-table-invite-status-accepted {
  background-color: #7bc333;
}

.user-table-invite-status-accepted::after {
  content: "angenommen";
}

.user-table-invite-status-send {
  background-color: cornflowerblue;
}

.user-table-invite-status-send::after {
  content: "gesendet";
}

.user-table-invite-status-processed {
  background-color: cornflowerblue;
}

.user-table-invite-status-processed::after {
  content: "verarbeitet";
}

.user-table-invite-status-delivered {
  background-color: cornflowerblue;
}

.user-table-invite-status-delivered::after {
  content: "zugestellt";
}

.user-table-invite-status-deferred {
  background-color: #ff5c5c;
}

.user-table-invite-status-deferred::after {
  content: "fehlgeschlagen";
}
.user-table-invite-status-dropped {
  background-color: #ff5c5c;
}

.user-table-invite-status-dropped::after {
  content: "fehlgeschlagen";
}
