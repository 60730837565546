.mollie-testmode-container {
  padding: 1rem;
  margin-bottom: 2rem;
  border: 1px solid rgb(138, 138, 0);
  color: rgb(68, 68, 0);
  background-color: rgb(255, 225, 133);
  border-radius: 4px;
  line-height: 1.5em;
}

.mollie-testmode-title {
  font-weight: 600;
}
