.course-date-input-container {
  display: flex;
}

.course-date-input-container input:last-child {
  margin-right: 0px;
}

.course-edit-container {
  width: 100%;
}

.course-participant {
  margin-top: 2rem;
}

.course-print-icon {
  margin-left: 0.5rem;
  cursor: pointer;
  color: black;
}

.two-input-container *:last-child {
  margin-right: 0px !important;
}

.adopt-settings-container {
  margin-bottom: 1rem;
}
