.member-container {
  /* padding: 1rem; */
  padding-bottom: 2rem;
  border: 1px solid darkgray;
  margin-bottom: 2rem;
}

.member-align-container {
  display: flex;
  width: 100%;
  margin-top: 2rem;
}

.member-align-container div:first-child {
  border-right: 1px solid rgb(228, 228, 228);
}

.member-inner-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.member-login-btn {
  margin-top: 1rem;
  border: 1px solid var(--primary-color) !important;
  border-radius: 20px;
  padding: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  color: var(--primary-color);
  width: fit-content;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .member-align-container {
    flex-direction: column;
  }

  .member-align-container div:first-child {
    border-right: none;
  }

  .member-inner-container:first-child {
    margin-bottom: 1.5rem;
  }

  .member-login-btn {
    margin-top: 0.5rem;
  }
}

.tag-rating-container {
  margin-top: 0.75rem;
}

.load-more-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  padding: 1rem 0;
  cursor: pointer;
}
