.create-course-modal::-webkit-scrollbar {
  width: 0rem;
}

.create-course-modal::-webkit-scrollbar-track {
  background: #1e1e24;
}

.create-course-modal::-webkit-scrollbar-thumb {
  background: #686868;
}

.modal-btn-container {
  display: flex;
  flex-direction: row;
}
.modal-btn-container button {
  margin-right: 1rem;
}

.modal-btn-container button:last-child {
  margin-right: 0px;
}

.course-time-input-container {
  display: flex;
}

.course-time-input-container input:last-child {
  margin-right: 0px;
}

/* .input-container {
  font-family: "Montserrat";
  padding: 8px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid black;
  width: 100%;
}

.input-container::placeholder {
  color: #b1b1b1;
} */

.expireDate-container {
  margin-right: 0px;
}

/* TABLE*/
.table-price {
  margin-bottom: 1rem;
  width: 100%;
  border-spacing: 0px;
  color: rgb(88, 88, 88);
  border: 1px solid var(--border-color);
  border-radius: 6px;
}

.table-price td {
  border-top: 1px solid var(--border-color);
  padding-right: 1rem;
  height: 3rem;
}

.table-price td:first-child,
.table-price th:first-child {
  padding-left: 1rem;
  padding-right: 1rem;
  width: 1px;
}

.table-price th {
  text-align: start;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #fbfbfb;
}
