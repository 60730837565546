.modal-btn-container {
  display: flex;
  flex-direction: row;
}
.modal-btn-container button {
  margin-right: 1rem;
}

.modal-btn-container button:last-child {
  margin-right: 0px;
}

.course-delete-table {
  margin-top: 1rem;
  max-height: 40vh;
  overflow: auto;
}

.course-delete-table::-webkit-scrollbar {
  width: 0.25rem;
}

.course-delete-table::-webkit-scrollbar-track {
  background: #686875;
}

.course-delete-table::-webkit-scrollbar-thumb {
  background: #aaaaaa;
}

.modal-loading-container {
  display: flex;
  flex: 1 1;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
