.qr-code-scanner-container {
  max-width: min(100vw, 300px);
  min-width: 300px;
  margin-right: 1rem;
  z-index: 0;
}

.inner-qr-code-container {
  display: flex;
}

.table-close-icon-container {
  background-color: rgb(240, 240, 240);
  color: rgb(153, 153, 153);
  position: absolute;
  top: 12px;
  right: 12px;
  border-radius: 50%;
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 200ms;
}

.table-close-icon-container:hover {
  background-color: rgb(235, 233, 233);
  color: rgb(138, 138, 138);
}

.table-field-icon-outer-container {
  position: relative;
}

.booking-information-table-disabled {
  cursor: not-allowed;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
}

.table-disabled-overlay {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(250, 250, 250, 0.6);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  font-family: "Montserrat";
  font-size: 2.5rem;
  font-weight: bold;
}

@media screen and (max-width: 800px) {
  .inner-qr-code-container {
    flex-direction: column;
  }
  .qr-code-scanner-container {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .qr-code-center-container {
    display: flex;
    justify-content: center;
  }

  .table-disabled-overlay {
    font-size: 2rem;
  }
}
