.method {
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.25rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border: 1px solid white;
  border-radius: 4px;
  cursor: pointer;
}

.current-payment-method {
  position: absolute;
  right: 0;
  top: 0;
  padding: 0.25rem;
  padding-right: 0.4rem;
  font-size: .85rem;
}

.method-selected {
  border: 1px solid #1a73e8;
}

.mandate-method-img {
  width: 40px;
}

.payment-details {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.payment-account {
  font-size: 0.85rem;
  color: gray;
}
