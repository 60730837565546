.mollie-connect-container {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  border-radius: 10px;
  background-color: rgb(127, 165, 255);
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  margin-bottom: 1rem;
}

.connect-text {
  font-size: 2rem;
}

.mollie-loading-container {
  margin-left: 1rem;
}

.mollie-onboarding-container {
  display: flex;
  margin-bottom: 2rem;
}

@media screen and (max-width: 960px) {
  .mollie-onboarding-container {
    flex-direction: column;
  }
  .mollie-connect-container {
    margin-bottom: 1rem;
  }
}

.mollie-onboarding-info-container {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.mollie-onboarding-info-inner-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 2rem;
  margin-right: 2rem;
  max-width: 30rem;
}

.mollie-onboarding-row {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.mollie-onboarding-title {
  font-weight: bold;
}

.mollie-onboarding-value {
  text-align: right;
  justify-content: flex-end;
}

.title-icon-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-items: center;
  margin-bottom: 1rem;
}

.lock-icon {
  cursor: pointer;
}

.lock-loading-container {
  margin-right: 0.5rem;
}
