.single-course-main-container {
  display: flex;
}

.single-course-img {
  color: rgb(194, 194, 194);
  min-height: 180px;
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  object-fit: contain;
  margin-right: 1rem;
}

@media screen and (max-width: 720px) {
  .single-course-img {
    display: block !important;
    width: 100%;
    min-height: 0 !important;
    max-height: 10rem !important;
    margin-bottom: 0.6rem;
    object-fit: cover;
  }

  .single-course-main-container {
    flex-direction: column;
  }
}

.alternative-price-table {
  font-weight: normal;
  border-collapse: collapse;
  /* border: 1px solid black; */
}

.alternative-price-used {
  font-weight: bold;
}

.alternative-price-table td {
  padding: 0.2rem;
}

.book-for-other-container {
  display: inline-block;
  font-weight: 600;
  padding: 0.6rem;
  border-radius: 0.5rem;
  margin-right: 1rem;
  cursor: pointer;
  transition: 200ms;
  color: #979797;
  background-color: white;
}

.book-for-other-container:hover {
  color: #329adf;
  background-color: #e1f3fc;
}

.has-children:hover .sub-menu li {
  -webkit-transform: perspective(350px) rotateX(0deg);
  -o-transform: perspective(350px) rotateX(0deg);
  transform: perspective(350px) rotateX(0deg);
  -webkit-transition: 0.2s linear 0s;
  -o-transition: 0.2s linear 0s;
  transition: 0.2s linear 0s;
}

.collapse-outer-container {
  height: 0px;
}

.collapse-outer-container .collapse-container {
  margin-top: 1rem;
  -webkit-transform-origin: 50% 0%;
  -o-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  -webkit-transform: perspective(350px) rotateX(-90deg);
  -o-transform: perspective(350px) rotateX(-90deg);
  transform: perspective(350px) rotateX(-90deg);
}

.collapse-outer-container .collapse-container:nth-child(1) {
  -webkit-transition: 0.2s linear 0.4s;
  -o-transition: 0.2s linear 0.4s;
  transition: 0.2s linear 0.4s;
}

.collapse-outer-container .collapse-container:nth-child(2) {
  -webkit-transition: 0.2s linear 0.2s;
  -o-transition: 0.2s linear 0.2s;
  transition: 0.2s linear 0.2s;
}

.collapse-outer-container .collapse-container:nth-child(3) {
  -webkit-transition: 0.2s linear 0s;
  -o-transition: 0.2s linear 0s;
  transition: 0.2s linear 0s;
}

.startAnimation {
  height: auto;
  margin-bottom: 1rem;
}

.startAnimation .collapse-container {
  -webkit-transform: perspective(350px) rotateX(0deg);
  -o-transform: perspective(350px) rotateX(0deg);
  transform: perspective(350px) rotateX(0deg);
  -webkit-transition: 0.2s linear 0s !important;
  -o-transition: 0.2s linear 0s !important;
  transition: 0.2s linear 0s !important;
}

.startAnimation .collapse-container:nth-child(2) {
  -webkit-transition-delay: 0.2s !important;
  -o-transition-delay: 0.2s !important;
  transition-delay: 0.2s !important;
}

.startAnimation .collapse-container:nth-child(3) {
  -webkit-transition-delay: 0.4s !important;
  -o-transition-delay: 0.4s !important;
  transition-delay: 0.4s !important;
}

.or-container {
  text-transform: uppercase;
  text-align: center;
}

.book-width-container {
  width: max-content;
}

.birthDate-container {
  justify-content: center;
}

.req-memApp-link {
  font-weight: normal;
  cursor: pointer;
  color: black;
  margin-left: 1rem;
  font-family: "Montserrat";
}
