.user-modal-inner {
  z-index: 1001;
  min-width: 20%;
  max-width: 90%;
  padding: 50px;
  background-color: white;
  color: black;
  border-radius: 25px;
  position: relative;
}

@media screen and (max-width: 450px) {
  .user-modal-inner {
    max-width: 95%;
    padding: 2.5rem 1.5rem 1.5rem 1.5rem;
  }
}

.modal-user-table {
  border-spacing: 1rem;
  border-collapse: collapse;
  margin-bottom: 2rem;
}

.modal-user-table thead tr th {
  padding-bottom: 1rem;
  font-size: 1.4rem;
}

.modal-user-table td {
  padding-right: 1vmin;
}

.modal-user-table td:last-child {
  padding-right: 0px;
}

.user-modal-input {
  height: 2.4rem;
  border: 1px solid black;
  outline: none;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1.2rem;
}
