.profile-location-container {
  display: flex;
}

.profile-location-container input:last-child {
  margin-right: 0px;
}

.zipcode-input {
  flex: 1;
}

.street-input {
  flex: 4;
}

.city-input {
  flex: 4;
}

.house-number-input {
  flex: 1;
}

.familie-account-btn-wrapper {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.familie-account-btn {
  padding: 0.75rem;
  background-color: var(--primary-color);
  color: var(--primary-text-color);
  border-radius: 30px;
  cursor: pointer;
}
