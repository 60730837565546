.course-date-container {
  border: 1px solid black;
  margin-bottom: 1rem;
  padding: 1rem;
}

.more-info-container {
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.more-info-inner-container {
  margin-right: 0.5rem;
}

.show-more-info-container {
  margin-top: 1rem;
}