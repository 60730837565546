.info-container {
  display: flex;
  background-color: rgb(238, 238, 238);
  padding: 0.6rem;
  border-radius: 10px;
  /* color: white; */
  margin-bottom: 1rem;
}

.info-circle-icon-container {
  background-color: white;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.info-icon {
  transform: scale(1.1);
}

.info-inner-container {
  padding-top: 2px;
}

.info-error-icon {
  color: #ff2e2e;
}

.info-info-icon {
  color: #fdbf2f;
}

.info-success-icon {
  color: #1ce025;
}
