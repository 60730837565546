.input-container {
  padding: 8px 20px;
  margin-right: 10px;
  outline: none;
  font-size: 18px;
  margin-bottom: 16px;
  width: 100%;
  font-family: "Montserrat";

  border: 1px solid #b8b8b8;
  border-radius: 8px;
  background-color: #f8f8f8;
}

.input-container::placeholder {
  color: #b1b1b1;
}
