.upload-main-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.upload-logo-container {
  display: flex;
  width: 20%;
  box-shadow: 0px 0px 10px rgb(69, 69, 69);
  align-items: center;
}

.upload-logo-container .background-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: rgb(194, 194, 194);
}

.upload-logo-container > img {
  width: 100%;
}

.image-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 1rem;
  border-top: 1rem solid rgb(193, 202, 206);
  box-shadow: 0px 0px 10px rgb(69, 69, 69);
}

.image-upper-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: space-evenly;
  color: rgb(163, 163, 163);
  padding: 0.75rem;
  cursor: pointer;
}

.image-upper-container .image-icon {
  color: rgb(194, 194, 194);
}

.image-upper-container .progress-bar {
  width: 100%;
}

.image-lower-container {
  display: flex;
  flex: 1;
  background-color: rgb(219, 219, 219);
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.image-input-container {
}

.delete-button {
  cursor: pointer;
  outline: none;
  border: none;
  padding: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  border-radius: 2rem;
  background-color: #ff3232;
  color: white;
  font-size: 1.2rem;
}

.upload-container {
}

.fileInput {
  display: none;
}
