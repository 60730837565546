.message-container {
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  padding: 1.5rem;
}

.message-error {
  background-color: rgba(255, 0, 0, 0.25);
  border-left: solid red 7px;
}

.message-success {
  background-color: rgba(0, 255, 0, 0.25);
  border-left: solid green 7px;
}
